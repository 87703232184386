@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 14px;
}

body {
  font-family: "Inter", sans-serif;
  color: #464c64;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

h1,
h2,
h3,
h4 {
  color: #282f44;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

details > summary {
  list-style: none;
  display: flex;
  justify-content: space-between;
  outline: 0;
  cursor: pointer;
}
summary::-webkit-details-marker {
  display: none;
}

summary::after {
  content: "›";
  font-size: 24px;
  transform: rotate(-90deg);
}
details[open] summary:after {
  content: "›";
  transform: rotate(90deg);
}
